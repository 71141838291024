APP.header = function () {
	const header = $('.header');
	const headerBG = header.find('.header__bg');
	const headerBody = header.find('.header__body');
	const navToggle = header.find('.nav-toggle');
	const navMenu = header.find('.nav-menu');
	const navMenuItem = navMenu.find('.nav-menu__item');
	const navMenuTitle = navMenuItem.find('.nav-menu__title');
	const navSub = navMenuItem.find('.nav-sub');
	const navSubMain = navSub.find('.nav-sub__main');
	const navModelItem = navSub.find('.nav-model__item');
	const navCar = navSub.find('.nav-car');
	const navRightItem = navSub.find('.nav-right__item');
	let isFlag = false;

	const navDropdown = header.find('.nav-dropdown');
	const navDropdownCurrent = navDropdown.find('.nav-dropdown__current');
	const navDropdownBody = navDropdown.find('.nav-dropdown__body');

	const paramToMenu = ['products', 'offer', 'service', 'news', 'about'];

	// get current query params
	const getQueryParams = function () {
		const urlParams = new URLSearchParams(window.location.search);
		const params = {};
		for (const [key, value] of urlParams) {
			params[key] = value;
		}
		return params;
	};

	// check if service params is in query params, then show menu
	const checkServiceParams = function () {
		const params = getQueryParams();
		if (typeof params.active_menu !== 'undefined' && paramToMenu.includes(params.active_menu)) {
			navMenuTitle.eq(paramToMenu.indexOf(params.active_menu)).trigger('click');
			navToggle.trigger('click');
		}
	};

	const init = function () {
		detectScroll();

		navMenuTitle.on('click', handleNavMenuTitle);
		navToggle.on('click', handleNavToggle);
		navDropdownCurrent.on('click', handleDropdown);
		checkServiceParams();

		$('.search-holder .btn').on('click', function (evt) {
			evt.preventDefault();
			const obj = $(this);
			const parent = obj.parent();
			const input = parent.find('.search-holder__control');
			const btnIcon = parent.find('.btn .icon');
			const value = input.val();

			if (value) {
				console.log('ok');
				parent.submit();
			} else {
				parent.toggleClass('is-active');

				if (parent.hasClass(CLASS._active)) {
					btnIcon.removeClass('icon--search').addClass('icon--search-black');
				} else {
					btnIcon.removeClass('icon--search-black').addClass('icon--search');
				}
			}
		});
	};

	const detectScroll = function () {
		const header = $('.header');
		const headerH = header.outerHeight(true);
		let lastScrollTop = 0;
		let scrollTop = WINDOW.scrollTop();

		if (scrollTop > headerH) {
			header.addClass(CLASS._move);
		} else {
			header.removeClass(CLASS._move);
		}

		WINDOW.on('scroll', function (evt) {
			let scrollTop = $(this).scrollTop();

			if (lastScrollTop <= 0) {
				lastScrollTop = 0;
			}

			if (scrollTop > lastScrollTop && !BODY.hasClass(CLASS._noscroll)) {
				if (scrollTop > headerH) {
					header.removeAttr('style').addClass(CLASS._move);
				} else {
					gsap.set(header, {
						y: -scrollTop,
					});
				}
			} else {
				header.removeAttr('style').removeClass(CLASS._move);
			}

			lastScrollTop = scrollTop;
		});
	};

	const handleNavMenuTitle = function (e) {
		e.preventDefault();
		const obj = $(this);
		const navMenuItemParent = obj.parent();
		const navSubActive = navMenuItemParent.find('.nav-sub');
		const navSubBGActive = navSubActive.find('.nav-sub__bg');
		const navModelItemActive = navSubActive.find('.nav-model__item');
		const navCarAcitve = navSubActive.find('.nav-car');
		const navRightItemActive = navSubActive.find('.nav-right__item');

		WIN_WIDTH = WINDOW.width();

		if (WIN_WIDTH >= 1200) {
			if (isFlag) return;
			isFlag = true;

			if (navMenuItemParent.hasClass(CLASS._active)) {
				if (!$('.summary__item').hasClass(CLASS._active)) {
					BODY.removeClass(CLASS._noscroll);
				}
				header.removeClass(CLASS._open).removeClass(CLASS._show);
				navMenuItemParent.removeClass(CLASS._active).removeClass(CLASS._current);
				navToggle.removeClass(CLASS._active);

				gsap.set(navSubMain, {
					alpha: 0,
				});

				gsap.set(navModelItem, {
					alpha: 1,
				});

				gsap.set(navCar, {
					alpha: 1,
					x: 0,
				});

				gsap.to(navSub, {
					duration: 0.3,
					y: '-100%',
					ease: 'power4.out',
					onComplete: function () {
						navSub.removeAttr('style');
						navSubMain.removeAttr('style');
						isFlag = false;
					},
				});
			} else {
				BODY.addClass(CLASS._noscroll);
				header.addClass(CLASS._open).addClass(CLASS._show);
				navMenuItem.removeClass(CLASS._active);
				navMenuItemParent.addClass(CLASS._active);
				navToggle.addClass(CLASS._active);

				if (navMenuItem.hasClass(CLASS._current)) {
					const navMenuItemCurrent = $('.nav-menu__item.is-current');
					const navModelItemCurrent = navMenuItemCurrent.find('.nav-model__item');
					const navCarCurrent = navMenuItemCurrent.find('.nav-car');
					const navRightItemCurrent = navMenuItemCurrent.find('.nav-right__item');

					if (navModelItemCurrent.length > 0) {
						gsap.to(navModelItemCurrent, {
							duration: 0.3,
							alpha: 0,
							stagger: {
								each: 0.03,
								from: 'end',
								ease: 'none',
								onComplete: function () {
									navMenuItem.removeClass(CLASS._current);
									navMenuItemParent.addClass(CLASS._current);
									navSub.removeAttr('style');

									gsap.set(navSubActive, {
										autoAlpha: 1,
										y: '0%',
										height: 'auto',
										zIndex: 2,
									});

									gsap.set(navSubBGActive, {
										x: '0%',
									});

									gsap.set(navModelItem, {
										alpha: 1,
									});

									if (navCarAcitve.length > 0) {
										gsap.set(navCarAcitve, {
											alpha: 0,
											x: -100,
										});

										gsap.to(navCarAcitve, {
											alpha: 1,
											x: 0,
											ease: 'power4.out',
											onComplete: function () {
												isFlag = false;
											},
										});
									}

									if (navRightItemActive.length > 0) {
										gsap.set(navRightItemActive, {
											alpha: 0,
											y: '-10%',
										});

										gsap.to(navRightItemActive, {
											duration: 0.3,
											alpha: 1,
											y: '0%',
											stagger: {
												each: 0.05,
												from: 'start',
												ease: 'none',
												onComplete: function () {
													isFlag = false;
												},
											},
										});
									}
								},
							},
						});
					}

					if (navRightItemCurrent.length > 0) {
						gsap.to(navCarCurrent, {
							alpha: 0,
							x: -100,
							ease: 'power4.out',
						});

						gsap.to(navRightItemCurrent, {
							duration: 0.3,
							alpha: 0,
							y: '-10%',
							stagger: {
								each: 0.05,
								from: 'end',
								ease: 'none',
							},
							onComplete: function () {
								navMenuItem.removeClass(CLASS._current);
								navMenuItemParent.addClass(CLASS._current);
								navSub.removeAttr('style');

								gsap.set(navSubActive, {
									autoAlpha: 1,
									y: '0%',
									height: 'auto',
									zIndex: 2,
								});

								gsap.set(navSubBGActive, {
									x: '0%',
								});

								gsap.set(navModelItem, {
									alpha: 1,
								});

								gsap.set(navCar, {
									alpha: 1,
									x: '0%',
								});

								gsap.set(navRightItem, {
									alpha: 1,
									y: '0%',
								});

								if (navModelItemActive.length > 0) {
									gsap.set(navModelItemActive, {
										alpha: 0,
									});

									gsap.to(navModelItemActive, {
										duration: 0.3,
										alpha: 1,
										delay: 0.15,
										stagger: {
											each: 0.03,
											from: 'start',
											ease: 'none',
										},
										onComplete: function () {
											isFlag = false;
										},
									});
								}

								if (navCarAcitve.length > 0) {
									gsap.set(navCarAcitve, {
										alpha: 0,
										x: -100,
									});

									gsap.to(navCarAcitve, {
										alpha: 1,
										x: 0,
										ease: 'power4.out',
										onComplete: function () {
											isFlag = false;
										},
									});
								}

								if (navRightItemActive.length > 0) {
									gsap.set(navRightItemActive, {
										alpha: 0,
										y: '-10%',
									});

									gsap.to(navRightItemActive, {
										duration: 0.3,
										alpha: 1,
										y: '0%',
										stagger: {
											each: 0.05,
											from: 'start',
											ease: 'none',
										},
										onComplete: function () {
											isFlag = false;
										},
									});
								}
							},
						});
					}
				} else {
					gsap.set(navSubActive, {
						autoAlpha: 0,
						y: '-100%',
						height: 'auto',
						zIndex: 2,
					});

					gsap.set(navSubBGActive, {
						x: '100%',
					});

					if (navModelItemActive.length > 0) {
						gsap.set(navModelItemActive, {
							alpha: 0,
						});
					}

					if (navCarAcitve.length > 0) {
						gsap.set(navCarAcitve, {
							alpha: 0,
							x: -100,
						});

						gsap.set(navRightItemActive, {
							alpha: 0,
							y: '-10%',
						});
					}

					gsap.to(navSubActive, {
						duration: 0.3,
						autoAlpha: 1,
						y: '0%',
						ease: 'power4.out',
						onComplete: function () {
							navMenuItemParent.addClass(CLASS._current);

							gsap.to(navSubBGActive, {
								duration: 0.3,
								x: '0%',
								delay: 0.3,
								ease: 'power4.out',
							});

							if (navModelItemActive.length > 0) {
								gsap.to(navModelItemActive, {
									duration: 0.3,
									alpha: 1,
									stagger: {
										each: 0.05,
										from: 'start',
										ease: 'none',
									},
									onComplete: function () {
										isFlag = false;
									},
								});
							}

							if (navCarAcitve.length > 0) {
								gsap.to(navCarAcitve, {
									alpha: 1,
									x: 0,
									ease: 'power4.out',
								});

								gsap.to(navRightItemActive, {
									duration: 0.1,
									alpha: 1,
									y: '0%',
									stagger: {
										each: 0.03,
										from: 'start',
										ease: 'none',
									},
									onComplete: function () {
										isFlag = false;
									},
								});
							}
						},
					});
				}
			}
		} else {
			if (navMenuItemParent.hasClass(CLASS._active)) {
				console.log(2222);
				navMenuItemParent.removeClass(CLASS._active).removeClass(CLASS._current);
				header.removeClass(CLASS._open);
				gsap.to(navSubActive, {
					duration: 0.3,
					autoAlpha: 0,
					height: 0,
					ease: 'power4.out',
				});
			} else {
				console.log(3333);
				navMenuItem.removeClass(CLASS._active).removeClass(CLASS._current);
				navMenuItemParent.addClass(CLASS._active).addClass(CLASS._current);
				header.addClass(CLASS._open);

				gsap.to(navSub, {
					duration: 0.3,
					height: 0,
					autoAlpha: 0,
					ease: 'power4.out',
					onComplete: function () {
						gsap.set(navSubActive, {
							autoAlpha: 1,
							height: 'auto',
							y: '0%',
						});

						gsap.from(navSubActive, {
							duration: 0.3,
							autoAlpha: 0,
							height: 0,
							ease: 'power4.out',
							onComplete: function () {
								gsap.set(navSubActive, {
									height: 'auto',
								});
							},
						});
					},
				});
			}
		}
	};

	const handleNavToggle = function (e) {
		e.preventDefault();
		if (!BODY.hasClass(CLASS._noscroll)) {
			BODY.addClass(CLASS._noscroll);
		}

		if (navToggle.hasClass(CLASS._active)) {
			navToggle.removeClass(CLASS._active);
			header.removeClass(CLASS._show);
			headerBG.removeAttr('style');
			console.log(1111);
			if (!navDropdown.hasClass(CLASS._open) && !$('.summary__item').hasClass(CLASS._active)) {
				BODY.removeClass(CLASS._noscroll);
			}
		} else {
			navToggle.addClass(CLASS._active);
			header.addClass(CLASS._show);

			gsap.set(headerBG, {
				x: '100%',
			});

			gsap.set(headerBody, {
				x: '100%',
			});

			gsap.to(headerBody, {
				duration: 0.3,
				x: '0%',
				ease: 'power4.out',
				onComplete: function () {
					headerBody.removeAttr('style');
				},
			});

			gsap.to(headerBG, {
				duration: 0.3,
				x: '0%',
				delay: 0.15,
				ease: 'power4.out',
			});
		}
	};

	const handleDropdown = function () {
		const obj = $(this);
		const parent = obj.parent();
		const navMobileH = $('.nav-mobile').outerHeight() - 2;

		parent.toggleClass(CLASS._open);

		if (!BODY.hasClass(CLASS._noscroll)) {
			BODY.addClass(CLASS._noscroll);
		}

		if (!parent.hasClass(CLASS._open)) {
			BODY.removeClass(CLASS._noscroll);
			gsap.to(navDropdownBody, {
				duration: 0.3,
				height: 0,
				ease: 'power1.ease',
				onComplete: function () {
					navDropdownBody.removeAttr('style');
				},
			});
		} else {
			gsap.set(navDropdownBody, {
				height: 'auto',
				top: navMobileH,
			});

			gsap.from(navDropdownBody, {
				duration: 0.3,
				height: 0,
				ease: 'power1.ease',
			});
		}
	};

	init();
};
