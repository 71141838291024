APP.productsGusr = function () {
	const gusrForm = $('.gusr-form');
	const mauXe = gusrForm.find('[name="mauxe"]');
	const labelMauXe = mauXe.data('label-text');
	const namSanXuat = gusrForm.find('[name="namsanxuat"]');
	const labelNamSanXuat = namSanXuat.data('label-text');
	const chonFileHuongDan = gusrForm.find('.chon-filehuongdan');
	const chooseFile = gusrForm.find('.choose-file');

	const [getOwnersManual, setOwnersManual] = useOwnersManual();
	const [getYears, setYears] = useYears();
	const [getFiles, setFiles] = useFiles();

	function useOwnersManual() {
		let _ownersManual = null;

		function getOwnersManual() {
			return _ownersManual;
		}

		function setOwnersManual(data) {
			_ownersManual = [...data];
		}

		return [getOwnersManual, setOwnersManual];
	}

	function useYears() {
		let _year = null;

		function getYears() {
			return _year;
		}

		function setYears(data) {
			_year = [...data];
		}

		return [getYears, setYears];
	}

	function useFiles() {
		let _file = null;

		function getFiles() {
			return _file;
		}

		function setFiles(data) {
			_file = [...data];
		}

		return [getFiles, setFiles];
	}

	async function getData(url) {
		$('.loading').show();
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				lang: _lang,
			},
		});

		const res = await response.json();

		if (res) $('.loading').hide();

		return res;
	}

	async function init() {
		if (gusrForm.length === 0) return;

		if (getOwnersManual() === null) {
			const data = await getData(`${api}/crm/service/owners_manual_crm`);
			if (data) {
				setOwnersManual(data.items);
				//console.log(getOwnersManual());

				renderModelsToDom(getOwnersManual());
				renderSelectModelsToDom(getOwnersManual());

				APP.validate('.gusr-form', {
					isAjax: true,
					onComplete: function (res) {
						//console.log(res.choose_file);
						window.open(res.choose_file);
					},
				});
			}
		}
	}

	function renderSelectModelsToDom(data) {
		let items = [`<option selected value="">${labelMauXe}</option>`];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(
					`<option value="${item?.model_name}" ${data.length === 1 ? 'selected' : ''}>${
						item?.model_name
					}</option>`
				);
			});
		}

		mauXe.html(items.join(''));
		mauXe.select2('destroy');
		mauXe.select2({
			with: 'resolve',
			//searchInputPlaceholder: placeholderProvince,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		mauXe.on('change', function () {
			const _this = $(this);
			const value = _this.val();
			if (value) {
				const yearData = getOwnersManual().find((item) => item?.model_name === value);
				if (yearData) {
					setYears(yearData?.years);
					renderSelectYearsToDom(getYears());
					namSanXuat.parent().removeClass('is-error').find('.form__error').html('');
				}
			} else {
				namSanXuat.val('').trigger('change');
				chonFileHuongDan.addClass('d-none');
				chooseFile.html('');
			}
		});
	}

	function renderSelectYearsToDom(data) {
		let items = [`<option selected value="">${labelNamSanXuat}</option>`];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(
					`<option value="${item?.year}" ${data.length === 1 ? 'selected' : ''}>${
						item?.year
					}</option>`
				);
				if (data.length === 1) {
					setDataFile(getYears(), item?.year);
				} else {
					chonFileHuongDan.addClass('d-none');
					chooseFile.parent().removeAttr('data-required');
					chooseFile.html('');
				}
			});
		}

		namSanXuat.html(items.join(''));
		namSanXuat.select2('destroy');
		namSanXuat.select2({
			with: 'resolve',
			//searchInputPlaceholder: placeholderProvince,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		namSanXuat.on('change', function () {
			const _this = $(this);
			const value = _this.val();
			if (value) {
				setDataFile(getYears(), value);
			} else {
				chonFileHuongDan.addClass('d-none');
				chooseFile.parent().removeAttr('data-required');
				chooseFile.html('');
			}
		});
	}

	function setDataFile(data, value) {
		const fileData = data.find((item) => item?.year === value);
		if (fileData) {
			if (fileData?.files !== false) {
				setFiles(fileData?.files);
				chonFileHuongDan.removeClass('d-none');
				chonFileHuongDan.parent().attr('data-required');
				//console.log(getFiles());
				renderFilesToDom(getFiles());
			}
		} else {
			chonFileHuongDan.addClass('d-none');
			chooseFile.parent().removeAttr('data-required');
		}
	}

	function renderModelItem(data) {
		const { model_name, image } = data;

		return `
			<div class="row__col row__col--lg-4">
				<div class="our-range-card" data-model-name="${model_name}">
					<div class="our-range-card__thumb">
						<img class="img img--full lazyload" data-src="${image}">
					</div>
					<div class="our-range-card__body">
						<h2 class="title title--6">${model_name}</h2>
					</div>
				</div>
			</div>
		`;
	}

	function renderModelsToDom(data) {
		let items = data.map((item) => renderModelItem(item)).join('');
		$('.product-gusr-list').html(items);

		const ourRangCard = $('.our-range-card');

		ourRangCard.on('click', function () {
			const modelName = $(this).data('model-name');
			APP.modal('.modal--termmmm');
			mauXe.val(modelName).trigger('change');
		});
	}

	function renderFileItem(data, index) {
		const { title, url } = data;

		return `
			<div class="row__col row__col--lg-6">
				<label class="variant-box__item" for="hdsd-xpander-${index}" data-download="${url}">
					<input id="hdsd-xpander-${index}" type="radio" name="choose_file" value="${url}" ${
			getFiles().length === 1 ? 'checked' : ''
		}>
					<div class="variant-box__content">
						<h4 class="title title--6">${title}</h4>
					</div>
				</label>
			</div>
		`;
	}

	function renderFilesToDom(data) {
		let items = data.map((item, index) => renderFileItem(item, index)).join('');
		chooseFile.html(items);
	}

	init();
};
