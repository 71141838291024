APP.testDriveCheckin = function () {
	const el = $('.test-drive-checkin');
	if (el.length === 0) return false;

	const holder = el.find('.test-drive-checkin__holder');
	const holderWidth = holder.innerWidth();
	const inner = el.find('.test-drive-checkin__inner');
	const loading = el.find('.loading');

	let isLoading = false;

	const form1 = el.find('.info-step1');
	const phone = form1.find('[name="phone"]');

	const form1otp = el.find('.info-step1-otp');
	const otpCode = form1otp.find('[name="otp_code"]');
	const changePhone = form1otp.find('.btn--change-phone');

	const form2 = el.find('.info-step2');
	const nvbhSelect = form2.find('[name="nvbh"]');
	const form2Action = form2.find('[name="is_regist_test_drive"]');
	const form2Accept = form2.find('.form__accept');
	const form2AcceptGroup = form2Accept.find('.form__group');
	const form2Button = form2.find('.btn');
	const form2ButtonText = form2Button.find('.btn__text');
	const form2ButtonNext = form2Button.data('text-next');
	const form2ButtonRegister = form2Button.data('text-register');
	const form2ModelSelect = form2.find('[name="model"]');

	const customerName = form2.find('.test-drive-checkin__customer');
	const customerPhoneLabel = form2.find('.test-drive-checkin__phone--label');
	const customerPhoneValue = form2.find('.test-drive-checkin__phone--value');
	const customerEmailLabel = form2.find('.test-drive-checkin__email--label');
	const customerEmailValue = form2.find('.test-drive-checkin__email--value');
	const customerAgencyLabel = form2.find('.test-drive-checkin__agency--value');
	const customerAgencyValue = form2.find('.test-drive-checkin__agency--value');

	const form2Update = el.find('.info-step2-update');
	const form2UpdateAction = form2Update.find('[name="is_regist_test_drive_update"]');
	const form2UpdateAccept = form2Update.find('.form__accept');
	const form2UpdateAcceptGroup = form2UpdateAccept.find('.form__group');
	const form2UpdateButton = form2Update.find('.btn');
	const form2UpdateButtonText = form2UpdateButton.find('.btn__text');
	const form2UpdateButtonNext = form2UpdateButton.data('text-next');
	const form2UpdateButtonRegister = form2UpdateButton.data('text-register');
	const form2UpdateModelSelect = form2Update.find('[name="model_update"]');

	const form3 = el.find('.info-step3');

	const form4 = el.find('.info-step4');
	const form4otp = el.find('.info-step4-otp');
	const btnResendSignature = $('.btn--resend-signature');
	const btnResendSignatureCount = btnResendSignature.find('.btn__count');

	const maxInputFile = 4;
	const btnInputFile = $('.btn-input-file');

	const thankYou = $('.thank-you-1');
	const thankYou2 = $('.thank-you-2');

	const backButtons = el.find('.btn-back');
	let currentFormPos = 0;
	let dataSubmit = {};
	let dataCheckPhone = {};

	let customerId = '';
	let userEventData = [];
	let userEventSelected = {};
	let dataSubmitLast = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
	};

	const validImageTypes1 = ['heif', 'heic'];
	const validImageTypes = ['dng'];
	let croppers = [];
	let currentInputFile = 0;

	let timeLeft = 120;
	let timerId;

	function animForm(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth : currentFormPos - holderWidth;

		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			delay: 0.15,
			ease: Power1.easeOut,
		});
	}

	function animForm1(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth * 2 : currentFormPos - holderWidth * 2;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function animForm2(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth * 3 : currentFormPos - holderWidth * 3;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function animForm3(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth : currentFormPos - holderWidth;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function animForm4(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth : currentFormPos - holderWidth;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function init() {
		btnInputFile.on('click', renderUploadFileToDom);

		const canvas = document.querySelector('canvas');

		const signaturePad = new SignaturePad(canvas, {
			//backgroundColor: 'rgb(255, 255, 255)',
		});

		signaturePad.addEventListener('endStroke', () => {
			gsap.set('.signature__clear', {
				autoAlpha: 1,
			});
			$('[name="image_signature_file"]').val(signaturePad.toDataURL('image/png'));
			$('[name="image_signature_file"]').parents('.form__group').removeClass(CLASS._error);
			$('[name="image_signature_file"]').parents('.form__group').find('.form__error').html('');
		});

		$('.signature__clear').on('click', function (e) {
			e.preventDefault();
			signaturePad.clear();
			gsap.set('.signature__clear', {
				autoAlpha: 0,
			});
			$('[name="image_signature_file"]').val('');
		});

		$('.signature__add').on('click', function (e) {
			e.preventDefault();
			const _this = $(this);
			const signature = _this.parents('.signature');
			const parent = signature.parent();
			const btnText = _this.find('.btn__text');
			const textSignature = _this.data('text-signature');
			const textSignatureAfter = _this.data('text-signature-after');

			if (!signature.hasClass('is-show')) {
				signature.addClass('is-show');
				btnText.text(textSignatureAfter);
				$('[name="sign_later"]').val(0);
				signaturePad.clear();
				parent.attr('data-required', true);
				$('.signature__body').show();
				$('.signature__note').hide();
			} else {
				signature.removeClass('is-show');
				btnText.text(textSignature);
				$('[name="sign_later"]').val(1);
				parent.removeAttr('data-required').removeClass('is-error');
				parent.find('.form__error').html('');
				$('.signature__body').hide();
				$('.signature__note').show();
			}
			$('[name="image_signature_file"]').val('');
		});

		form3.on('change', '.file-upload-image input[type="file"]', async function (e) {
			if (e.target.files.length === 0) return;
			const _this = $(this);
			const value = _this.val();
			const parent = _this.parents('.form__group');
			const fileBrowser = parent.find('.file-browser__text');
			const fileProgress = parent.find('.file-progress');
			const imagePreview = parent.find('.image-preview');
			const fileProgressPercent = fileProgress.find('.file-progress__percent');
			const preview = parent.find('.image-preview__gplx');

			const fileUploadHidden = $('[name="file-upload-hidden"]');
			const fileUploadHiddenParrent = fileUploadHidden.parent();
			const fileUploadHiddenError = fileUploadHiddenParrent.find('.form__error');

			fileBrowser.hide();

			function createPreviewImage(imageurl) {
				const previewItem = $(`<div class="image-preview-item" style="padding: 1vw 0;"></div>`);
				const image = new Image();
				image.src = imageurl;
				previewItem.append(image);
				preview.append(previewItem);
				function onLoadImage() {
					const cropper = new Cropper(image, {
						viewMode: 3,
						zoomOnTouch: false,
						zoomable: false,
						zoomOnWheel: false,
						autoCrop: true,
						aspectRatio: 3 / 2,
						responsive: false,
					});
					croppers.push(cropper);
				}
				image.addEventListener('load', onLoadImage);
				fileProgress.hide();
				imagePreview.show();
				fileUploadHidden.val(value);
				fileUploadHiddenParrent.removeClass('is-error');
				fileUploadHiddenError.html('');
				parent.find('.file-upload-image__hidden').hide();
			}

			if (validImageTypes.includes(e.target.files[0].name.split('.').pop())) {
				fileProgress.show();
				parent.find('.file-upload-image__hidden').show();

				let formData = new FormData();
				formData.append('file', e.target.files[0]);
				formData.append('user_id', customerId);
				formData.append('note', 'Test-Drive-Event');

				$.ajax({
					url: `${api}/file/convert`,
					type: 'POST',
					data: formData,
					xhr: function () {
						var xhr = new window.XMLHttpRequest();
						xhr.upload.addEventListener(
							'progress',
							function (evt) {
								if (evt.lengthComputable) {
									var percentComplete = evt.loaded / evt.total;
									fileProgressPercent.css({
										width: `${Math.round(percentComplete * 100)}%`,
									});
								}
							},
							false
						);
						return xhr;
					},
					success: function (data) {
						const {
							result,
							item: { url_file_convert },
						} = data;
						if (result === 'success') {
							createPreviewImage(url_file_convert);
						}
					},
					cache: false,
					contentType: false,
					processData: false,
				});
			} else if (validImageTypes1.includes(e.target.files[0].name.split('.').pop())) {
				const blob = e.target.files[0];
				heic2any({
					blob,
					toType: 'image/jpeg',
					quality: 0.5,
				})
					.then((conversionResult) => {
						var image = URL.createObjectURL(conversionResult);
						createPreviewImage(image);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				const preview = parent.find(`.image-preview__gplx`);
				preview.html('');
				[...e.target.files].map((file) => {
					image = URL.createObjectURL(file);
					createPreviewImage(image);
				});
			}
		});

		form3.on('click', '.image-preview__remove', function (e) {
			e.preventDefault();
			const _this = $(this);
			const parent = _this.parents('.form__group');
			const inputFile = parent.find('input');
			const fileBrowser = parent.find('.file-browser__text');
			const imagePreview = parent.find('.image-preview');

			inputFile.val('');
			fileBrowser.show();
			imagePreview.hide();
		});

		form3.on('click', '.file-browser__remove', function (e) {
			e.preventDefault();
			const _this = $(this);
			const parent = _this.parents('.file-upload-image');
			parent.remove();
			currentInputFile--;
			$('.btn-input-file').show();
			$('.file-upload-image').map(function (i, e) {
				if (i === 0) return;
				const _this = $(this);
				const fileTitle = _this.find('.file-upload-title');
				fileTitle.html(`Hình ảnh ${i}`);
			});
		});
		if ($('[name="visibled_signature"]').val() === '0') {
			$('.signature__add').hide();
		}

		phone.focus();
		// Step 1
		APP.validate(form1, {
			isAjax: true,
			onComplete: function (data) {
				const visibled_signature = $('[name="visibled_signature"]').val();
				dataSubmit = { ...dataSubmit, ...data, visibled_signature };
				if (isLoading) return;
				isLoading = true;
				loading.show();

				fetch(`${api}/dealer/signature/crm/customer`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						isLoading = false;
						loading.hide();

						// if (data?.data?.items.length === 1) {
						// 	form2Action[1].checked = true;
						// 	// form2Button.trigger('click');
						// 	form2Action.trigger('change');
						// }

						if (data?.data?.is_test_drive || data?.data?.is_participation) {
							form2Action[1].checked = true;
							form2UpdateAction[1].checked = true;
							form2Action.trigger('change');
							form2UpdateAction.trigger('change');
							$('.select-hoatdong').hide();
						} else {
							$('.select-hoatdong').show();
						}

						if (!data?.data?.event_info?.[0]?.isTestDriveEvent) {
							$('#dang-ky-lai-thu').parent().hide();
							$('#dangkylaithu_update').parent().hide();
							form2Action[0].checked = true;
							form2UpdateAction[0].checked = true;
							form2Action.trigger('change');
							form2UpdateAction.trigger('change');
							$('.select-hoatdong').hide();
						}
						if (data?.data?.is_participation && !data?.data?.event_info?.[0]?.isTestDriveEvent) {
							thankYou2.find('.title').html(' ' + data?.data?.item?.name);
							TweenMax.to(thankYou2, 0.4, {
								autoAlpha: 1,
								display: 'block',
								ease: Power1.easeOut,
							});
							return;
						}
						if (data?.data?.event_info?.[0]?.listConfigModel) {
							const models = [];
							data?.data?.event_info?.[0]?.listConfigModel.forEach((item) => {
								if (!models.find((model) => model.modelCarId === item.modelCarId)) {
									models.push(item);
								}
							});
							form2ModelSelect.append(
								models
									.map((item) => `<option value="${item.modelCarId}">${item.modelCar}</option>`)
									.join('')
							);
							form2UpdateModelSelect.append(
								models
									.map((item) => `<option value="${item.modelCarId}">${item.modelCar}</option>`)
									.join('')
							);
						}

						if (data?.result === 'fail') {
							form1.find('.form__error--response').html(data.errorMessage);
						} else if (!data?.data?.phone_number_verified) {
							otpCode.val(data?.data?.otp_code);
							animForm();
							setTimeout(() => {
								otpCode.focus();
							}, 500);

							customerId = data?.data?.id;

							dataSubmitLast = {
								...dataSubmitLast,
								phone: dataSubmit?.phone,
								dealer_code: dataSubmit?.dealer_code,
								event_code: dataSubmit?.event_code,
								crm_customer_id: '',
							};

							dataCheckPhone = {
								phone: data?.data?.phone,
								id: data?.data?.id,
							};

							APP.validate(form1otp, {
								isAjax: true,
								onComplete: function (data) {
									dataCheckPhone = {
										...dataCheckPhone,
										...data,
									};
									if (isLoading) return;
									isLoading = true;
									loading.show();
									fetch(`${api}/dealer/signature/customer/verify`, {
										method: 'POST',
										headers: {
											'Content-Type': 'application/json',
											Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
											lang: _lang,
										},
										body: JSON.stringify(dataCheckPhone),
									})
										.then((res) => res.json())
										.then((data) => {
											isLoading = false;
											loading.hide();

											if (data?.result === 'fail') {
												form1otp.find('.form__error--response').html(data.errorMessage);
											} else {
												animForm1();
											}
										});
								},
							});
						} else if (!data?.data?.registered_event) {
							customerId = data?.data?.id;

							dataSubmitLast = {
								...dataSubmitLast,
								phone: dataSubmit?.phone,
								dealer_code: dataSubmit?.dealer_code,
								event_code: dataSubmit?.event_code,
								crm_customer_id: '',
							};
							animForm2();
						} else {
							animForm1();
							customerId = data?.data?.id;
							userEventData = data?.data?.items;

							if (userEventData.length > 0) {
								userEventSelected = userEventData[0];
								renderSelectSale(userEventData);
								renderCustomer(userEventSelected);

								dataSubmitLast = {
									...dataSubmitLast,
									name: userEventSelected?.name,
									phone: data?.data?.phone,
									email: userEventSelected?.email_full,
									sales_consultant: userEventSelected?.brokerName,
									dealer_code: dataSubmit?.dealer_code,
									event_code: dataSubmit?.event_code,
									crm_customer_id: '',
								};
							}

							if (!data?.data?.has_broker_name) {
								nvbhSelect.parent().removeAttr('data-required').hide();
								$('.select-nvbh').hide();
							} else {
								nvbhSelect.parent().attr('data-required');
								$('.select-nvbh').show();
							}
							dataSubmitLast.crm_customer_id = userEventSelected?.id;
							// if (data?.data?.items.length === 1) {
							// 	form2Action[1].checked = true;
							// 	// form2Button.trigger('click');
							// 	form2Action.trigger('change');
							// 	//$('.select-hoatdong').hide();
							// 	console.log(5555);
							// }
							// if (data?.data?.is_test_drive) {
							// 	form2Action[1].checked = true;
							// 	form2UpdateAction[1].checked = true;
							// 	form2Action.trigger('change');
							// 	form2UpdateAction.trigger('change');
							// 	$('.select-hoatdong').hide();
							// 	console.log(6666);
							// }
						}

						if (data?.data?.registered_event) {
							$('[name="name"]').val(data?.data?.items[0]?.name);
							$('[name="email"]').val(data?.data?.items[0]?.email_full);
							$('[name="tvbh"]').val(data?.data?.items[0]?.dealerName);
							$('[name="name"]').parent().addClass('is-filled');
							$('[name="email"]').parent().addClass('is-filled');
							$('[name="tvbh"]').parent().addClass('is-filled');
						}
					})
					.catch((error) => {
						isLoading = false;
						loading.hide();
						console.log(error);
					});
			},
		});

		form2Action.on('change', function () {
			const _this = $(this);
			if (_this.is(':checked')) {
				form2ModelSelect.val('');
				const val = parseInt(_this.val());
				if (val === 0) {
					form2Accept.show();
					form2AcceptGroup.attr('data-required', 'data-required');
					form2ButtonText.text(form2ButtonRegister);
					form2ModelSelect.parent().removeAttr('data-required').addClass('d-none');
				} else {
					form2Accept.hide();
					form2AcceptGroup
						.removeClass('is-error')
						.removeClass('is-focus')
						.removeAttr('data-required');
					form2AcceptGroup.find('.form__error').html('');
					form2ButtonText.text(form2ButtonNext);
					form2ModelSelect.parent().attr('data-required', 'data-required').removeClass('d-none');
				}
			}
		});

		form2UpdateAction.on('change', function () {
			const _this = $(this);
			if (_this.is(':checked')) {
				const val = parseInt(_this.val());
				if (val === 0) {
					form2UpdateAccept.show();
					form2UpdateAcceptGroup.attr('data-required', 'data-required');
					form2UpdateButtonText.text(form2UpdateButtonRegister);
					form2UpdateModelSelect.parent().removeAttr('data-required').addClass('d-none');
				} else {
					form2UpdateAccept.hide();
					form2UpdateAcceptGroup
						.removeClass('is-error')
						.removeClass('is-focus')
						.removeAttr('data-required');
					form2UpdateAcceptGroup.find('.form__error').html('');
					form2UpdateButtonText.text(form2UpdateButtonNext);
					form2UpdateModelSelect
						.parent()
						.attr('data-required', 'data-required')
						.removeClass('d-none');
				}
			}
		});

		changePhone.on('click', function () {
			animForm(true);
			phone.val('');
			setTimeout(() => {
				phone.focus();
			}, 500);
		});

		APP.validate(form2, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmitLast = {
					...dataSubmitLast,
					sales_consultant: data.nvbh,
					is_regist_test_drive: data.is_regist_test_drive,
					model: data.model,
				};
				if (data.is_regist_test_drive === '0') {
					const formData = new URLSearchParams();
					for (let key in dataSubmitLast) {
						formData.append(key, dataSubmitLast[key]);
					}
					submitData(form2Update, formData);
				} else {
					animForm1();
				}
			},
		});

		APP.validate(form2Update, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmitLast = {
					...dataSubmitLast,
					name: data.name,
					email: data.email,
					sales_consultant: data.nvbh,
					is_regist_test_drive: data.is_regist_test_drive_update,
					model: data.model_update,
				};
				if (data.is_regist_test_drive_update === '0') {
					const formData = new URLSearchParams();

					for (let key in dataSubmitLast) {
						formData.append(key, dataSubmitLast[key]);
					}

					submitData(form2Update, formData);
				} else {
					animForm();
				}
			},
		});

		APP.validate(form3, {
			isAjax: true,
			onComplete: function (data) {
				if (isLoading) return;
				isLoading = true;
				loading.show();

				dataSubmitLast = {
					...dataSubmitLast,
					driving_licence: data.driving_licence,
				};

				const files = croppers.map((item) => {
					return item.getCroppedCanvas().toDataURL('image/jpeg');
				});

				const dataUpload = {
					note: dataSubmitLast.event_code,
					files,
					id_user: customerId,
				};

				fetch(`${api}/file/upload`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(dataUpload),
				})
					.then((res) => res.json())
					.then((data) => {
						isLoading = false;
						loading.hide();
						if (data.result === 'success') {
							form1.find('.form__error--response').html('');
							const fileIds = data.files.map((item) => item.id).join(',');
							dataSubmitLast = {
								...dataSubmitLast,
								file_id: fileIds,
							};
							animForm3();
						} else {
							form3.find('.form__error--response').html(data.errorMessage);
						}
					});
			},
		});

		APP.validate(form4, {
			isAjax: true,
			onComplete: function (resForm4) {
				const { sign_later, image_signature_file } = resForm4;

				if (sign_later === '0') {
					const dataUpload = {
						note: dataSubmitLast.event_code + '-signature',
						files: [image_signature_file],
						id_user: customerId,
					};

					loading.show();

					fetch(`${api}/file/upload`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							lang: _lang,
						},
						body: JSON.stringify(dataUpload),
					})
						.then((res) => res.json())
						.then((data) => {
							loading.hide();

							if (data.result === 'success') {
								form1.find('.form__error--response').html('');
								const fileIds = data.files.map((item) => item.id).join(',');
								dataSubmitLast = {
									...dataSubmitLast,
									...resForm4,
									image_signature: fileIds,
								};

								const formData = new URLSearchParams();
								for (let key in dataSubmitLast) {
									formData.append(key, dataSubmitLast[key]);
								}

								submitData(form4, formData);
							} else {
								form4.find('.form__error--response').html(data.errorMessage);
							}
						});
				} else {
					dataSubmitLast = {
						...dataSubmitLast,
						...resForm4,
					};

					const formData = new URLSearchParams();
					for (let key in dataSubmitLast) {
						formData.append(key, dataSubmitLast[key]);
					}

					submitData(form4, formData);
				}
			},
		});

		APP.validate(form4otp, {
			isAjax: true,
			onComplete: function (data) {
				dataCheckPhone = {
					...dataCheckPhone,
					otp_code: data.otp_code_signature,
					type: 'signature',
				};
				if (isLoading) return;
				isLoading = true;
				loading.show();

				fetch(`${api}/dealer/signature/otp/verify`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
						lang: _lang,
					},
					body: JSON.stringify(dataCheckPhone),
				})
					.then((res) => res.json())
					.then((data) => {
						isLoading = false;
						loading.hide();

						if (data?.result === 'fail') {
							form4otp.find('.form__error--response').html(data.errorMessage);
						} else {
							TweenMax.to(el, 0.4, {
								autoAlpha: 0,
								ease: Power1.easeOut,
								onComplete: function () {
									el.hide();
								},
							});

							if (data?.item?.orderIdTestDriveName) {
								thankYou.find('.title').html(' ' + data.item.name);
								thankYou.find('.thank-you__sttname').html(' ' + data.item.orderIdTestDriveName);
								TweenMax.to(thankYou, 0.4, {
									autoAlpha: 1,
									display: 'block',
									ease: Power1.easeOut,
								});
							} else {
								thankYou2.find('.title').html(' ' + data.item.name);

								TweenMax.to(thankYou2, 0.4, {
									autoAlpha: 1,
									display: 'block',
									ease: Power1.easeOut,
								});
							}
						}
					});
			},
		});

		// Back buttons
		backButtons.on('click', function (e) {
			animForm(true);
			e.preventDefault();
		});

		thankYou.find('.btn-close').on('click', function (e) {
			TweenMax.to(thankYou, 0.4, {
				autoAlpha: 0,
				display: 'none',
				ease: Power1.easeOut,
				onComplete: function () {},
			});
			// remove param ticket_code
			const url = new URL(window.location.href);
			url.searchParams.delete('ticket_code');
			window.location.href = url.href;
		});

		btnResendSignature.on('click', function (evt) {
			evt.preventDefault();
			// resend api otp
			loading.show();
			fetch(`${api}/dealer/signature/otp/resend`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
					lang: _lang,
				},
				body: JSON.stringify(dataCheckPhone),
			})
				.then((res) => res.json())
				.then((data) => {
					isLoading = false;
					loading.hide();

					if (data?.result === 'fail') {
						form4otp.find('.form__error--response').html(data.errorMessage);
					} else {
						btnResendSignature.addClass(CLASS._disabled);
						timerId = setInterval(countdown, 1000);
						if (data.otp_code !== '') {
							$('[name="otp_code_signature"]').val(data?.data?.signature_otp_code);
							$('[name="otp_code_signature"]').parent().addClass(CLASS._focus);
							setTimeout(() => {
								$('[name="otp_code_signature"]').focus();
							}, 500);
						}
					}
				});
		});
	}

	function submitData(form, formData) {
		if (isLoading) return;
		isLoading = true;
		loading.show();

		fetch(`${api}/dealer/signature/crm/customer/${customerId}`, {
			method: 'PUT',
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				isLoading = false;
				loading.hide();
				if (data.result === 'success') {
					if (dataSubmitLast.sign_later === '0' && data?.data?.signature_otp_code !== 'undefined') {
						$('[name="otp_code_signature"]').val(data?.data?.signature_otp_code);
						animForm4();
						setTimeout(() => {
							$('[name="otp_code_signature"]').focus();
						}, 500);
						$('.phone-signature').html(`${dataSubmitLast?.phone}`);
						timerId = setInterval(countdown, 1000);
					} else {
						TweenMax.to(el, 0.4, {
							autoAlpha: 0,
							ease: Power1.easeOut,
							onComplete: function () {
								el.hide();
							},
						});

						if (data?.item?.orderIdTestDriveName) {
							thankYou.find('.title').html(' ' + data.item.name);
							thankYou.find('.thank-you__sttname').html(' ' + data.item.orderIdTestDriveName);
							TweenMax.to(thankYou, 0.4, {
								autoAlpha: 1,
								display: 'block',
								ease: Power1.easeOut,
							});
						} else {
							thankYou2.find('.title').html(' ' + data.item.name);

							TweenMax.to(thankYou2, 0.4, {
								autoAlpha: 1,
								display: 'block',
								ease: Power1.easeOut,
							});
						}
					}
				} else {
					form.find('.form__error--response').html(data.errorMessage);
				}
			});
	}

	function renderSelectSale(data) {
		let items = [];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(`<option value="${item.brokerName}">${item.brokerName}</option>`);
			});
		}

		nvbhSelect.html(items.join(''));
		nvbhSelect.select2('destroy');
		nvbhSelect.select2({
			with: 'resolve',
			minimumResultsForSearch: -1,
			//searchInputPlaceholder: chooseExtendSelectPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		nvbhSelect.on('change', function () {
			const obj = $(this);
			const val = obj.val();
			let userEventChoose = userEventData.find((item) => item.brokerName === val);
			if (val === userEventSelected.brokerName) {
				dataSubmitLast.crm_customer_id = '';
			} else {
				dataSubmitLast.crm_customer_id = userEventChoose.id;
			}
			renderCustomer(userEventChoose);
		});
	}

	function renderCustomer(data) {
		if (data.name) customerName.html(' ' + data.name);
		if (data.phone) {
			customerPhoneLabel.show();
			customerPhoneValue.html(' ' + data.phone + '<br/>');
		} else {
			customerPhoneLabel.hide();
		}
		if (data.email) {
			customerEmailLabel.show();
			customerEmailValue.html(' ' + data.email + '<br/>');
		} else {
			customerEmailLabel.hide();
		}
		if (data.dealerShortName) {
			customerAgencyLabel.show();
			customerAgencyValue.html(' ' + data.dealerShortName + '<br/>');
		} else {
			customerAgencyLabel.hide();
		}
	}

	// Clone .file-upload-image
	const fileUploadImage = $('.file-upload-image').eq(0).clone();
	function renderUploadFile(idx) {
		const fileUploadImageClone = fileUploadImage.clone();

		fileUploadImageClone
			.find('[name="file_GPLX"]')
			.attr('data-add-file', idx)
			.attr('name', `file_GPLX_${idx}`)
			.attr('id', `file_GPLX_${idx}`);

		fileUploadImageClone.find('label').attr('for', `file_GPLX_${idx}`);

		fileUploadImageClone
			.find('.file-browser')
			.prepend(`<button type="button" class="file-browser__remove">Remove</button>`);

		$('.file-upload-image').eq(idx).after(fileUploadImageClone);

		$('.file-upload-image').map(function (i, e) {
			if (i === 0) return;
			const _this = $(this);
			const fileTitle = _this.find('.file-upload-title');
			fileTitle.html(`Hình ảnh ${i}`);
		});

		return fileUploadImageClone;
	}

	function renderUploadFileToDom(e) {
		e.preventDefault();
		if (currentInputFile >= maxInputFile - 1) {
			$('.btn-input-file').hide();
		}

		if (currentInputFile < maxInputFile) {
			renderUploadFile(currentInputFile);
			// $('.file-upload__render').append(uploadItem);
			currentInputFile++;
		}
	}

	function countdown() {
		if (timeLeft < 0) {
			clearTimeout(timerId);
			timeLeft = 120;
			btnResendSignature.removeClass(CLASS._disabled);
			btnResendSignatureCount.html('(120s)');
		} else {
			btnResendSignatureCount.html(`(${timeLeft}s)`);
			timeLeft--;
		}
	}

	init();
};
