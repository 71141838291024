APP.modal = function (element, options) {
	const el = $(element);
	const navbar = $('.navbar');
	const modalDialog = el.find('.modal__dialog');
	const modalClose = modalDialog.find('.modal__close');
	let modalDialogHeight = modalDialog.innerHeight();
	let scrollbarWidth = APP.getScrollbarWidth();

	const settings = $.extend(
		{
			isOverlay: false,
			onComplete: function () {},
		},
		options
	);

	function init() {
		detectModal();
		APP.resize(detectModal);
		animateModal();

		modalClose.on('click', hideModal);

		if (settings.isOverlay) {
			el.on('click', function (evt) {
				const obj = $(evt.target);
				if (obj.closest('.modal__dialog').length === 0) {
					hideModal();
				}
			});
		}
	}

	function detectModal() {
		APP.getResize();
		modalDialogHeight = modalDialog.innerHeight();
		console.log(WIN_HEIGHT, modalDialogHeight);
		if (modalDialogHeight > WIN_HEIGHT) {
			el.addClass(CLASS._scroll);
		} else {
			el.removeClass(CLASS._scroll);
		}
	}

	function animateModal() {
		BODY.css({
			paddingRight: scrollbarWidth,
		}).addClass(CLASS._noscroll);

		navbar
			.css({
				paddingRight: scrollbarWidth,
			})
			.addClass(CLASS._noscroll);

		el.addClass(CLASS._show);
		TweenMax.set(modalDialog, {
			autoAlpha: 0,
			y: 100,
		});

		TweenMax.to(modalDialog, 0.4, {
			autoAlpha: 1,
			y: 0,
			ease: Power1.easeOut,
			onComplete: function () {
				detectModal();
			},
		});
	}

	function hideModal() {
		TweenMax.to(modalDialog, 0.4, {
			autoAlpha: 0,
			y: 100,
			ease: Power1.easeOut,
			onComplete: function () {
				el.removeClass(CLASS._show).removeClass(CLASS._scroll);
				// if (!$('.modal').hasClass(CLASS._show)) {
				BODY.removeAttr('style').removeClass(CLASS._noscroll);
				navbar.removeAttr('style').removeClass(CLASS._noscroll);
				// }
			},
		});
	}

	init();
};
