APP.bookAService = function () {
	const el = $('.book-a-service');
	const formBookAService = el.find('.form--book-a-service');
	const selectModel = formBookAService.find('[name="model"]');
	const selectModelText = selectModel.data('label-model');
	const selectModelInputPlacehoder = selectModel.data('input-placeholder');
	const selectJobKind = formBookAService.find('[name="job_kind"]');
	const selectJobKindText = selectJobKind.data('label-service');
	const selectDealer = formBookAService.find('[name="dealer_code"]');
	const selectDealerText = selectDealer.data('label-dealer');
	const selectDealerInputPlaceholder = selectDealer.data('input-placeholder');
	const yourDealerTime = formBookAService.find('[name="your_dealer_time"]');
	const yourPreferedDate = formBookAService.find('[name="your_prefered_date"]');
	const datepicker = formBookAService.find('[data-toggle="datepicker"]');
	const formGroupOther = formBookAService.find('.form__group--other');
	const modelOther = formGroupOther.find('[name="model_other"]');
	const selectService = formBookAService.find('[name="service"]');

	const modalTime = $('.modal--time');
	const cardTime = modalTime.find('.card-time');
	const noDataText = cardTime.data('no-data');
	const filterSession = modalTime.find('.filter-session');
	const filterStatus = modalTime.find('.filter-status');
	const btnConfirm = modalTime.find('.btn--confirm');
	const filterSessionItem = filterSession.find('.filter-session__item');
	const filterStatusItem = filterStatus.find('.filter-status__item');

	const chooseExtend = $('.choose-extend');
	const chooseExtendSelect = chooseExtend.find('select');
	const chooseExtendSelectLabel = chooseExtendSelect.data('label-text');
	const chooseExtendSelectPlaceholder = chooseExtendSelect.data('input-placeholder');
	const dealercode = el.find('[name="dealercode"]');

	const bookAServiceConfirm = $('.book-a-service-confirm');
	const btnCancel = bookAServiceConfirm.find('.btn--cancel');
	const btnSubmitConfirm = bookAServiceConfirm.find('.btn--submit-confirm');
	const loading = bookAServiceConfirm.find('.loading');
	const bookAServiceConfirmName = bookAServiceConfirm.find('.book-a-service-confirm__value--name');
	const bookAServiceConfirmEmail = bookAServiceConfirm.find(
		'.book-a-service-confirm__value--email'
	);
	const bookAServiceConfirmPhone = bookAServiceConfirm.find(
		'.book-a-service-confirm__value--phone'
	);
	const bookAServiceConfirmModel = bookAServiceConfirm.find(
		'.book-a-service-confirm__value--model'
	);
	const bookAServiceConfirmLicence = bookAServiceConfirm.find(
		'.book-a-service-confirm__value--licence'
	);
	const bookAServiceConfirmSerive = bookAServiceConfirm.find(
		'.book-a-service-confirm__value--service'
	);
	const bookAServiceConfirmDealer = bookAServiceConfirm.find(
		'.book-a-service-confirm__value--dealer'
	);
	const bookAServiceConfirmDate = bookAServiceConfirm.find('.book-a-service-confirm__value--date');
	const bookAServiceConfirmTime = bookAServiceConfirm.find('.book-a-service-confirm__value--time');
	const bookAServiceConfirmError = bookAServiceConfirm.find('.book-a-service-confirm__error');

	const bookAServiceThank = $('.book-a-service-thank');
	const ctaRowService = $('.cta-row--service');

	const [getTime, setTime] = useTime();
	const [getInfo, setInfo] = useInfo();

	function init() {
		if (el.length === 0) return;

		APP.getModels(selectModel, selectModelText, selectModelInputPlacehoder, 1);
		APP.getServices(selectJobKind, selectJobKindText);
		APP.getDealerGroup(selectDealer, selectDealerText, selectDealerInputPlaceholder);

		selectModel.on('change', function () {
			const obj = $(this);
			const value = parseInt(obj.val());

			if (value === 0) {
				formGroupOther.show();
				modelOther.val('');
			} else {
				formGroupOther.hide();
				formGroupOther.removeClass(CLASS._error);
				formGroupOther.find('.form__error').text('');
				modelOther.val(value);
			}
		});

		datepicker.datepicker({
			autoHide: true,
			format: 'yyyy-mm-dd',
			language: _lang === 'en' ? 'en-US' : 'vi-VN',
			startDate: new Date(),
			hide: function () {
				if (datepicker.datepicker('getDate', true) !== datepicker.val()) {
					yourDealerTime.val('');
					yourDealerTime.parent().removeClass(CLASS._filled);
				}
			},
		});

		selectDealer.on('change', function () {
			const obj = $(this);
			const value = obj.val();
			const date = yourPreferedDate.val();
			if (value && value !== 'null' && value !== 'all' && date !== '') {
				yourDealerTime.prop('disabled', false);
			} else {
				yourDealerTime.prop('disabled', true).val('');
				yourDealerTime.parent().removeClass(CLASS._filled);
			}
		});

		filterSessionItem.on('click', handleFilterSession);
		filterStatusItem.on('click', handleFilterStatus);
		btnCancel.on('click', handleCancel);
		btnSubmitConfirm.on('click', handleSubmitConfirm);

		yourPreferedDate.on('blur', function () {
			setTimeout(() => {
				const obj = $(this);
				const value = obj.val();
				const dealerCode = selectDealer.val();

				if (value !== '' && dealerCode !== 'null' && dealerCode !== 'all') {
					yourDealerTime.prop('disabled', false);
				} else {
					yourDealerTime.prop('disabled', true);
				}
			}, 150);
		});

		yourDealerTime.on('click', async function () {
			const selectDealerValue =
				typeof hasBranch !== 'undefined' && hasBranch ? selectService.val() : selectDealer.val();
			const yourPreferedDateValue = yourPreferedDate.val();

			if (
				selectDealerValue === '' ||
				selectDealerValue === 'null' ||
				yourPreferedDateValue === '' ||
				yourPreferedDateValue === 'null'
			)
				return;

			const data = await getData(
				`${api}/service/dealerTime?date=${yourPreferedDateValue}&dealer_code=${selectDealerValue}`
			);
			setTime(data);
			renderCardTimeItem(getTime());
			APP.modal('.modal--time');
		});

		btnConfirm.on('click', function (e) {
			e.preventDefault();
			const fromTime = $('[name="from-time"]').val();
			if (fromTime !== '') {
				yourDealerTime.val(fromTime).focus();
				filterStatusItem.removeClass(CLASS._active);
				filterSessionItem.removeClass(CLASS._active);
				filterSessionItem.eq(0).addClass(CLASS._active);
				yourDealerTime.parent().removeClass(CLASS._error);
				yourDealerTime.next().text('');
				$('.modal__close').trigger('click');
			}
		});

		if (typeof hasBranch !== 'undefined' && hasBranch) {
			const crmServiceCodeArr = JSON.parse(crmServiceCode);
			chooseExtend.show().find('.form__group').attr('data-required', 'data-required');
			selectDealer.parent().hide().removeAttr('data-required');
			renderSelectSale(crmServiceCodeArr);
		} else {
			chooseExtend.hide().find('.form__group').removeAttr('data-required');
			selectDealer.parent().show().attr('data-required', 'data-required');
		}

		APP.validate('.form--book-a-service', {
			isAjax: true,
			onComplete: function (res) {
				const model_name = selectModel.find('option:selected').text();
				const service_name = selectJobKind.find('option:selected').text();
				const dealer_code =
					typeof hasBranch !== 'undefined' && hasBranch ? selectService.val() : selectDealer.val();
				const dealer_name =
					typeof hasBranch !== 'undefined' && hasBranch
						? selectService.find('option:selected').text()
						: selectDealer.find('option:selected').text();
				const data = {
					...res,
					appointment_date: res.your_prefered_date + ' ' + res.your_dealer_time,
					service_name,
					dealer_name,
					dealer_code,
					model_name,
				};

				setInfo(data);
				renderInfoUser(data);
				el.hide();
				bookAServiceConfirm.show();

				// tracking to CDP
				dataLayer.push({
					event: 'pangoTrack',
					eventAction: 'book-service',
					cdpData: {
						properties: {
							model: parseInt(data.model) === 0 ? data.model_other : data.model_name,
							serviceName: data.service_name,
							name: data.name,
							phone: data.phone,
							email: data.email,
						},
					},
				});
				// end tracking
			},
		});
	}

	function renderSelectSale(data) {
		let items = [`<option selected value="">${chooseExtendSelectLabel}</option>`];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(`<option value="${item.code}">${item.name}</option>`);
			});
		}

		chooseExtendSelect.html(items.join(''));
		chooseExtendSelect.select2('destroy');
		chooseExtendSelect.select2({
			with: 'resolve',
			searchInputPlaceholder: chooseExtendSelectPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		chooseExtendSelect.on('change', function () {
			const obj = $(this);
			const val = obj.val();
			dealercode.val(val);
		});
	}

	function useTime() {
		let _time = null;

		function getTime() {
			return _time;
		}

		function setTime(data) {
			_time = [...data];
		}

		return [getTime, setTime];
	}

	function useInfo() {
		let _info = null;

		function getInfo() {
			return _info;
		}

		function setInfo(data) {
			_info = { ...data };
		}

		return [getInfo, setInfo];
	}

	async function getData(url) {
		loading.show();

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				lang: _lang,
			},
		});

		const res = await response.json();

		if (!res.errorCode) {
			setTimeout(() => {
				loading.hide();
			}, 1000);
		}

		return res.items;
	}

	function cardTimeItem(data) {
		const { fromTime, hasCalendar, hasGift } = data;

		return `
		<div class="${
			hasCalendar ? `card-time__item is-disabled` : `card-time__item`
		}" data-from-time="${fromTime}">
			<div class="card-time__body">
				<div class="card-time__icon">
					${
						hasCalendar
							? `<i class="icon icon--close-circle"></i>`
							: `<i class="icon icon--checked-circle"></i>`
					}
					${hasGift ? `<i class="icon icon--gift-circle"></i>` : ''}
				</div>
				<div class="card-time__text">${fromTime}</div>
			</div>
		</div>
		`;
	}

	function toTimestamp(strDate) {
		var datum = Date.parse(strDate);
		return datum / 1000;
	}

	function renderCardTimeItem(data) {
		const now = new Date();
		let myDate = yourPreferedDate.val();
		myDate = myDate.split('-');
		const day = now.getDate();
		const month = now.getMonth() + 1;
		const year = now.getFullYear();
		const hour = now.getHours();
		const minute = now.getMinutes();

		let items = data
			.map((item, index) => {
				const date = toTimestamp(`${myDate[1]}/${myDate[2]}/${myDate[0]} ${item.fromTime}:00`);
				const dateNow = toTimestamp(`${month}/${day}/${year} ${hour}:${minute}:00`);
				if (date > dateNow) {
					return cardTimeItem(item, index + 1);
				}
			})
			.join('');
		if (items) {
			cardTime.html(items);
		} else {
			cardTime.html(`<p class="card-time__nodata">${noDataText}</p>`);
		}

		if (items) {
			const cardTimeItem = cardTime.find('.card-time__item');
			cardTimeItem.on('click', function () {
				const obj = $(this);
				const fromTime = obj.data('from-time');
				$('[name="from-time"]').val(fromTime);
				cardTimeItem.removeClass(CLASS._checked);
				obj.addClass(CLASS._checked);
				btnConfirm.prop('disabled', false);
			});
		}
	}

	function renderInfoUser(data) {
		const {
			name,
			email,
			phone,
			model,
			model_name,
			model_other,
			license_plate,
			your_dealer_time,
			your_prefered_date,
			service_name,
			dealer_name,
		} = data;

		if (name) bookAServiceConfirmName.html(name);
		if (email) bookAServiceConfirmEmail.html(email);
		else bookAServiceConfirmEmail.html('');
		if (phone) bookAServiceConfirmPhone.html(phone);
		if (model) bookAServiceConfirmModel.html(`${parseInt(model) === 0 ? model_other : model_name}`);
		if (license_plate) bookAServiceConfirmLicence.html(license_plate);
		else bookAServiceConfirmLicence.html('');
		if (dealer_name) bookAServiceConfirmDealer.html(dealer_name);
		if (service_name) bookAServiceConfirmSerive.html(service_name);
		if (your_prefered_date) bookAServiceConfirmDate.html(your_prefered_date);
		if (your_dealer_time) bookAServiceConfirmTime.html(your_dealer_time);
	}

	function handleFilterSession() {
		const obj = $(this);
		const value = obj.data('session');
		const data = getTime();
		const filteredItems = data.filter((item) => (value === 'all' ? item : value === item.session));
		filterStatusItem.removeClass(CLASS._active);
		filterSessionItem.removeClass(CLASS._active);
		obj.addClass(CLASS._active);
		renderCardTimeItem(filteredItems);
	}

	function handleFilterStatus() {
		const obj = $(this);
		const value = parseInt(obj.data('status'));
		const session = $('.filter-session__item.is-active').data('session');
		const data = getTime();
		filterStatusItem.removeClass(CLASS._active);
		obj.addClass(CLASS._active);
		const filteredItems = data.filter((item) =>
			session === 'all'
				? value === 2
					? item.hasGift
					: value === 0
					? item.hasCalendar
					: !item.hasCalendar
				: value === 2
				? item.hasGift && item.session === session
				: value === 0
				? item.hasCalendar && item.session === session
				: !item.hasCalendar && item.session === session
		);
		renderCardTimeItem(filteredItems);
	}

	function handleCancel() {
		el.show();
		bookAServiceConfirm.hide();
	}

	function handleSubmitConfirm() {
		loading.show();

		const data = getInfo();

		fetch(`${api}/service/booking`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				lang: _lang,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((data) => {
				loading.hide();
				if (data.result === 'success') {
					bookAServiceConfirm.hide();
					bookAServiceThank.show();
					ctaRowService.show();
				} else {
					bookAServiceConfirmError.html(data.errorMessage);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	init();
};
